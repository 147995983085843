.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

.locate {
  position: absolute;
  top: 60%;
  left: 8%;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 50px;
  cursor: pointer;
}

.search {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 400px;
  z-index: 10;
}

.search input {
  padding: 0.5rem;
  font-size: 1.5rem;
  width: 100%;
}
